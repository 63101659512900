<template>
  <div class="flex ml-auto content-center space-x-2 mb-2">
    <c-btn
      class="self-end focus:outline-none"
      v-for="{ label, handler, colorClass } in config"
      :key="label"
      :class="colorClass"
      @click="handler"
      :label="label"
    />
  </div>
</template>
<script>
export default {
  name: 'Button-Group',
  props: {
    config: {
      type: Array,
      required: true,
    },
  },
}
</script>
