import { formatNumber } from '@/utils/number'

function _formatDollar(value) {
  return (value < 0 ? '-' : '') + '$' + formatNumber(Math.abs(value))
}

export default {
  chart: {
    height: 600,
    width: 800,
  },
  legend: {
    enabled: true,
  },
  rangeSelector: { inputEnabled: false },
  title: { text: 'Profit Loss' },
  subtitle: { text: '' },
  xAxis: [
    {
      type: 'datetime',
    },
  ],
  yAxis: [
    {
      visible: true,
      opposite: false,
      title: { text: 'Earn' },
      labels: {
        formatter: function () {
          return _formatDollar(this.value)
        },
      },
    },
    {
      visible: true,
      opposite: true,
      title: { text: 'Total' },
      labels: {
        formatter: function () {
          return _formatDollar(this.value)
        },
      },
    },
  ],
  series: [
    {
      name: 'Earn',
      color: '#244eb8',
      type: 'column',
      data: [],
      yAxis: 0,
      tooltip: { valuePrefix: '$ ' },
    },
    {
      name: 'Total Profit',
      color: '#5eac0d',
      data: [],
      type: 'spline',
      yAxis: 1,
      tooltip: { valuePrefix: '$ ' },
    },
  ],
}
