const TYPE_TEXT = 'text'
const TYPE_NUMBER = 'number'
const TYPE_SELECT_BOX = 'select'
const FIELDS = [
  {
    env: {
      text: 'Env.:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'Production',
        },
        {
          key: 2,
          text: 'Staging',
        },
      ],
    },
  },
  {
    exchange: {
      text: 'Exchange:',
      type: TYPE_TEXT,
      disabled: true,
    },
    symbol: {
      text: 'Symbol:',
      type: TYPE_TEXT,
      disabled: true,
    },
    token: {
      text: 'Bit-Copy API token:',
      type: TYPE_TEXT,
    },
    productId: {
      text: 'Bit-Copy Product ID:',
      type: TYPE_NUMBER,
    },
    key: {
      text: 'API key:',
      type: TYPE_TEXT,
    },
    secret: {
      text: 'API Secret:',
      type: TYPE_TEXT,
    },
    subAccount: {
      text: 'Sub account (FTX only):',
      type: TYPE_TEXT,
    },
  },
  {
    upperLine: {
      text: 'Upper line (Toraripi):',
      type: TYPE_NUMBER,
    },
    lowerLine: {
      text: 'Lower line (Toraripi):',
      type: TYPE_NUMBER,
    },
    entrySpread: {
      text: 'Entry spread:',
      type: TYPE_NUMBER,
    },
    profitSpread: {
      text: 'Profit spread:',
      type: TYPE_NUMBER,
    },
    side: {
      text: 'Entry side:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'BUY',
        },
        {
          key: 2,
          text: 'SELL',
        },
      ],
    },
    checkSide: {
      text: 'HANTEI side:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'BUY',
        },
        {
          key: 2,
          text: 'SELL',
        },
      ],
    },
    liquidationLot: {
      text: 'HANTEI Lot:',
      type: TYPE_NUMBER,
    },
    upperLiquidationLot: {
      text: 'HANTEI Lot (UPPER Limit):',
      type: TYPE_NUMBER,
    },
    lowerLiquidationLot: {
      text: 'HANTEI Lot (LOWER Limit):',
      type: TYPE_NUMBER,
    },
    lot: {
      text: 'Entry Lot:',
      type: TYPE_NUMBER,
    },
    targetExchange: {
      text: 'Target exchange:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 'bybit',
          text: 'BYBIT',
        },
        {
          key: 'BitMEX',
          text: 'BITMEX',
        },
        {
          key: 'Bitfinex',
          text: 'BITFINEX',
        },
        {
          key: 'FTX',
          text: 'FTX',
        },
      ],
    },
    lot100M: {
      text: 'Lot - 100M:',
      type: TYPE_NUMBER,
    },
    lot10M: {
      text: 'Lot - 10M:',
      type: TYPE_NUMBER,
    },
    lot1M: {
      text: 'Lot - 1M:',
      type: TYPE_NUMBER,
    },
    entryChange: {
      text: 'Entry change:',
      type: TYPE_NUMBER,
    },
    activeEntryMin: {
      text: 'Active Entry Period (in minute):',
      type: TYPE_NUMBER,
    },
  },
  {
    diff1: {
      text: 'Diff (1) (%):',
      type: TYPE_NUMBER,
    },
    diff2: {
      text: 'Diff (2) (%):',
      type: TYPE_NUMBER,
    },
    multi1: {
      text: 'Multi (1):',
      type: TYPE_NUMBER,
    },
    multi2: {
      text: 'Multi (2):',
      type: TYPE_NUMBER,
    },
    initTag: {
      text: 'Logic Tag:',
      type: TYPE_TEXT,
    },
  },
  {
    hanteiCondition1: {
      text: 'HANTEI condition (1):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    grayScaleDiff1: {
      text: 'Grayscale Lot (1):',
      type: TYPE_NUMBER,
    },
    side1: {
      text: 'Entry side (1):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'BUY',
        },
        {
          key: 2,
          text: 'SELL',
        },
      ],
    },
    lot1: {
      text: 'Entry Lot: (1)',
      type: TYPE_NUMBER,
    },
    tp1: {
      text: 'TP (1) (in USD):',
      type: TYPE_NUMBER,
    },
    sl1: {
      text: 'SL (1) (in USD):',
      type: TYPE_NUMBER,
    },
    hanteiCondition2: {
      text: 'HANTEI condition (2):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    grayScaleDiff2: {
      text: 'Grayscale Lot (2):',
      type: TYPE_NUMBER,
    },
    side2: {
      text: 'Entry side (2):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'BUY',
        },
        {
          key: 2,
          text: 'SELL',
        },
      ],
    },
    lot2: {
      text: 'Entry Lot: (2)',
      type: TYPE_NUMBER,
    },
    tp2: {
      text: 'TP (2) (in USD):',
      type: TYPE_NUMBER,
    },
    sl2: {
      text: 'SL (2) (in USD):',
      type: TYPE_NUMBER,
    },
    hanteiCondition3: {
      text: 'HANTEI condition (3):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    grayScaleDiff3: {
      text: 'Grayscale Lot (3):',
      type: TYPE_NUMBER,
    },
    side3: {
      text: 'Entry side (3):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'BUY',
        },
        {
          key: 2,
          text: 'SELL',
        },
      ],
    },
    lot3: {
      text: 'Entry Lot: (3)',
      type: TYPE_NUMBER,
    },
    tp3: {
      text: 'TP (3) (in USD):',
      type: TYPE_NUMBER,
    },
    sl3: {
      text: 'SL (3) (in USD):',
      type: TYPE_NUMBER,
    },
  },
  {
    mintedHigh: {
      text: 'Minted Upper limit (USD)',
      type: TYPE_NUMBER,
    },
    mintedLow: {
      text: 'Minted Lower limit (USD)',
      type: TYPE_NUMBER,
    },
    tpPercent: {
      text: 'TP (%):',
      type: TYPE_NUMBER,
    },
    slPercent: {
      text: 'SL (%):',
      type: TYPE_NUMBER,
    },
    rangePercent: {
      text: 'Change in 5 hour (%):',
      type: TYPE_NUMBER,
    },
    rangeBars: {
      text: 'Number of continuous bars:',
      type: TYPE_NUMBER,
    },
    percentChange: {
      text: 'Short term range limit (%):',
      type: TYPE_NUMBER,
    },
    hanteiRate: {
      text: 'HANTEI price:',
      type: TYPE_NUMBER,
    },
    hanteiPercent: {
      text: 'HANTEI rate (%):',
      type: TYPE_NUMBER,
    },
    hanteiCondition: {
      text: 'HANTEI condition:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    entryHanteiCondition: {
      text: 'HANTEI condition (entry):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    exitHanteiCondition: {
      text: 'HANTEI condition (exit):',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 1,
          text: 'UP',
        },
        {
          key: 2,
          text: 'DOWN',
        },
      ],
    },
    hanteiSpan: {
      text: 'HANTEI span (in seconds):',
      type: TYPE_NUMBER,
    },
    resolution: {
      text: 'Indicator resolution:',
      type: TYPE_SELECT_BOX,
      items: [
        { key: 1, text: '1m' },
        { key: 5, text: '5m' },
        { key: 15, text: '15m' },
        { key: 30, text: '30m' },
        { key: 60, text: '1h' },
        { key: 240, text: '4h' },
        { key: 1440, text: '1D' },
      ],
    },
    bars: {
      text: 'Indicator Period:',
      type: TYPE_NUMBER,
    },
    maBars1: {
      text: 'MA Bar (1):',
      type: TYPE_NUMBER,
    },
    maBars2: {
      text: 'MA Bar (2):',
      type: TYPE_NUMBER,
    },
    tp: {
      text: 'TP (in USD):',
      type: TYPE_NUMBER,
    },
    sl: {
      text: 'SL (in USD):',
      type: TYPE_NUMBER,
    },
    exitMin: {
      text: 'Exit time (in minute):',
      type: TYPE_NUMBER,
    },
    fluctuation: {
      text: 'Flunctuation:',
      type: TYPE_NUMBER,
    },
    spotNetFlow: {
      text: 'Spot vs Derivative:',
      type: TYPE_SELECT_BOX,
      items: [
        {
          key: 0,
          text: 'DERIVATIVE',
        },
        {
          key: 1,
          text: 'SPOT',
        },
      ],
    },
    exchangeNetflow: {
      text: 'Exchange Netflow (limit):',
      type: TYPE_NUMBER,
    },
    orderSecond: {
      text: 'Wait period before order (in seconds):',
      type: TYPE_NUMBER,
    },
  },
  {
    upperDiv1: {
      text: 'Upper deviation rate - 1 (%):',
      type: TYPE_NUMBER,
    },
    lowerDiv1: {
      text: 'Lower deviation rate - 1 (%):',
      type: TYPE_NUMBER,
    },
    upperDiv2: {
      text: 'Upper deviation rate - 2 (%):',
      type: TYPE_NUMBER,
    },
    lowerDiv2: {
      text: 'Lower deviation rate - 2 (%):',
      type: TYPE_NUMBER,
    },
    entryEnvelop: {
      text: 'Entry envelop side:',
      type: TYPE_SELECT_BOX,
      items: [
        { key: 3, text: 'UP-1' },
        { key: 4, text: 'UP-2' },
        { key: 2, text: 'SMA' },
        { key: 1, text: 'LOW-1' },
        { key: 0, text: 'LOW-2' },
      ],
    },
    exitEnvelop: {
      text: 'Exit envelop side (TP):',
      type: TYPE_SELECT_BOX,
      items: [
        { key: 3, text: 'UP-1' },
        { key: 4, text: 'UP-2' },
        { key: 2, text: 'SMA' },
        { key: 1, text: 'LOW-1' },
        { key: 0, text: 'LOW-2' },
      ],
    },
  },
  {
    maxPosition: {
      text: 'Max position:',
      type: TYPE_NUMBER,
    },
    dailyOrderLimit: {
      text: 'Daily Order Limit:',
      type: TYPE_NUMBER,
    },
    orderSpan: {
      text: 'Entry Time Span (in seconds)',
      type: TYPE_NUMBER,
    },
    reOpenSpan: {
      text: 'Re-Entry Time Span (in seconds) - After Close',
      type: TYPE_NUMBER,
    },
  },
]

function _number(text) {
  return { text, type: TYPE_NUMBER }
}

function _text(text) {
  return { text, type: TYPE_TEXT }
}

/*
" [Tradeの設定項目]
・Hantei_m1
・Hantei_m2
・Entry
・Exit
・Re-entry Time span"
*/
const CUSTOM_FIELDS = {
  'hotelo-rotate': {
    entryPeriod: _number('Hantei_m1 (in minutes) - for Entry'),
    closePeriod: _number('Hantei_m2 (in minutes) - for Close'),
    entryPercent: _number('Entry (%)'),
    exitPercent: _number('Exit (%)'),
    tp: _number('Take profit'),
  },
  'twitter-keyword': {
    channel: _text('Twitter channel'),
    keywords: _text('Keywords (separate by comma - ,)'),
  },
}

module.exports = {
  FIELDS,
  CUSTOM_FIELDS,
  TYPE_TEXT,
  TYPE_NUMBER,
  TYPE_SELECT_BOX,
}
