import {
  FIELDS,
  CUSTOM_FIELDS,
  TYPE_NUMBER,
  TYPE_SELECT_BOX,
  TYPE_TEXT,
} from './_configFields'
import { Chart } from 'highcharts-vue'
import chartOptions from './_chartFields'
import { maskingAPIKey } from '@/utils/string'
import ButtonGroup from './button-group'
import cloneDeep from 'lodash.clonedeep'

function _2digit(val) {
  return val > 9 ? '' + val : '0' + val
}

export default {
  name: 'Configs',
  components: {
    Chart,
    ButtonGroup,
  },
  data() {
    return {
      tableHeaders: [
        'Exchange',
        'On/Off',
        'Logic',
        'Status',
        'Target Exchange',
        'Lot',
        'Tp',
        'Sl',
        'Memo',
      ],
      configs: [],
      selected: {},
      isAll: false,
      searchText: '',
      target: null,
      targetConfig: {},
      FIELDS,
      TYPE_TEXT,
      TYPE_NUMBER,
      TYPE_SELECT_BOX,
      chartOptions: null,
    }
  },
  async created() {
    this.configs = await this.$http.get('/api/v1/configs')
  },
  methods: {
    isConfigOn({ config: { on } }) {
      return on === 1
    },
    openModal(item) {
      this.target = cloneDeep(item)
      this.targetConfig = this.target.config
    },
    closeModal() {
      this.target = null
      this.targetConfig = {}
    },
    _switch() {
      this.targetConfig.on = 1 - this.targetConfig.on
    },
    onChangeReduceOnly() {
      this.targetConfig.reduceOnly = 1 - this.targetConfig.reduceOnly
    },
    _clearSelect() {
      this.selected = {}
      this.isAll = false
    },
    selectAll() {
      if (this.isAll) {
        this._clearSelect()
      } else {
        this.configs.forEach(({ magicNumber }) => {
          this.$set(this.selected, magicNumber, true)
        })
        this.isAll = true
      }
    },
    async reloadConfigs() {
      this.configs = await this.$http.get('/api/v1/configs')
      this._clearSelect()
      this.closeModal()
    },
    check({ magicNumber }) {
      if (this.selected[magicNumber]) {
        this.$delete(this.selected, magicNumber.toString())
        return
      }
      this.$set(this.selected, magicNumber, true)
    },
    async enableLogics(on) {
      const magicNumbers = []
      Object.keys(this.selected).forEach((el) => magicNumbers.push(el))
      await this.$http.post('/api/v1/configs/switch', { magicNumbers, on })
      await this.reloadConfigs()
    },
    switchConfig(item) {
      const cloned = cloneDeep(item)
      const { config, magicNumber, memo } = cloned
      config.on = 1 - config.on
      this.$http
        .post(`/api/v1/configs/${magicNumber}`, { config: config, memo })
        .then(() => {
          this.reloadConfigs()
        })
    },
    updateConfigInModal(item) {
      const cloned = cloneDeep(item)
      const { config, magicNumber, memo, name } = cloned
      this.$http
        .post(`/api/v1/configs/${magicNumber}`, { config: config, memo, name })
        .then(() => {
          this.reloadConfigs()
        })
    },
    async resetEntryTime(magicNumber) {
      if (magicNumber instanceof Event) {
        magicNumber = null
      }
      const magicNumbers = []
      if (magicNumber) {
        magicNumbers.push(magicNumber)
      } else {
        Object.keys(this.selected).forEach((_magicNumber) => {
          if (this.selected[_magicNumber]) {
            magicNumbers.push(_magicNumber)
          }
        })
      }
      if (magicNumbers.length > 0) {
        await this.$http.post('/api/v1/configs/reset/entry/time', { magicNumbers })
        this.reloadConfigs()
      }
    },
    async showLogicChart(config) {
      const { magicNumber, name } = config
      this.chartOptions = cloneDeep(chartOptions)
      this.chartOptions.subtitle.text = name

      const url = `/api/v1/positions?magicNumber=${magicNumber}&status=2&limit=1000`
      const logicHistory = await this.$http.get(url)
      logicHistory.data.sort((a, b) => {
        const tsa = new Date(a.openAt).getTime()
        const tsb = new Date(b.openAt).getTime()
        return tsa - tsb
      })

      let total = 0
      logicHistory.data.forEach(({ openAt, pl }) => {
        total += pl
        const ts = new Date(openAt).getTime()
        this.chartOptions.series[0].data.push([ts, pl])
        this.chartOptions.series[1].data.push([ts, total])
      })
    },
    status(item) {
      item.resetable = false
      if (item.config.on === 0) {
        return 'Locked'
      }
      const now = Date.now()
      const {
        lastEntryTime,
        lastCloseTime,
        config: { orderSpan = 0, reOpenSpan = 0 },
      } = item
      const next = Math.max(
        lastEntryTime + orderSpan * 1e3,
        lastCloseTime + reOpenSpan * 1e3,
        now - 5e3
      )

      if (now < next) {
        item.resetable = true
        const date = new Date(next)
        const month = _2digit(date.getMonth() + 1)
        const day = _2digit(date.getDate())
        const hour = _2digit(date.getHours())
        const min = _2digit(date.getMinutes())
        return `Pending (~${month}/${day} ${hour}:${min})`
      }

      if (item.status === 2) {
        return 'Holding open position'
      }

      return 'Running'
    },
    _type(key) {
      const { type } = this.customFields[key] || this.allFields[key] || {}
      return type || TYPE_TEXT
    },
    _url(item) {
      if (item.config.productId) {
        return `https://bit-copy.com/ja/trader/${item.config.productId}`
      }
    },
    _label(key) {
      const { text } = this.customFields[key] || this.allFields[key] || {}
      return text || key
    },
    _disable(key) {
      const { disabled } = this.customFields[key] || this.allFields[key] || {}
      return disabled
    },
  },
  computed: {
    configList() {
      return Object.keys(this.target.config).filter((key) => {
        return !['on', 'reduceOnly'].includes(key)
      })
    },
    customFields() {
      return CUSTOM_FIELDS[this.target.logicCode] || {}
    },
    allFields() {
      let acc = {}
      FIELDS.forEach((fields) => {
        Object.keys(fields).forEach((key) => {
          acc[key] = fields[key]
        })
      })
      return acc
    },
    showActions() {
      return this.isAll || Object.keys(this.selected).length > 0
    },
    getConfigs() {
      if (!this.searchText) {
        return this.configs
      }

      const searchFields = ['name', 'memo', 'magicNumber', 'exchangeUserId']
      return this.configs.filter((config) => {
        let isExist = false
        searchFields.forEach((field) => {
          if (
            config[field] &&
            config[field].toString().includes(this.searchText)
          ) {
            isExist = true
          }
        })

        if (!isExist) {
          isExist = maskingAPIKey(this.searchText) === config.config.key
        }

        return isExist
      })
    },
  },
}
